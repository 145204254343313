$(document).ready(function() {
    $('.icon').mouseenter(function() {
        var rolloverIcon = $(this).find('a').data('rollover');
        if (rolloverIcon != null) {
            var image = $(this).find('img');
            image.attr('src', rolloverIcon);
        }
    }).mouseleave(function() {
        var rolloverIcon = $(this).find('a').data('icon');
        if (rolloverIcon != null) {
            var image = $(this).find('img');
            image.attr('src', rolloverIcon);
        }
    });
});